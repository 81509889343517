import { ConfigHolidayGadget } from '@supersonic/config';

const holidayGadget: ConfigHolidayGadget = {
  recentSearches: {
    enabled: true,
    localStorageKey: 'icelolly-holiday-recent-searches',
  },
  backTab: {
    clickTripzApi: 'https://www.clicktripz.com/x/pas',
    desktopPlacementId: '3264-1',
    mobilePlacementId: '3927-1',
    siteId: 'icelolly',
    timestampLocalStorageKey: 'icelolly-back-tab-timestamp',
  },
  passengersSelector: { fieldLabel: 'Who' },
  airportsSelector: { fieldLabel: 'From' },
  durationSelector: { fieldLabel: 'How long' },
  destinationSelector: {
    fieldLabel: 'Where to',
    autocompleteApi: process.env.AUTOCOMPLETE_API_URL as string,
    showAnywhereDestination: true,
  },
  dateSelector: { fieldLabel: 'When' },
  layout: {
    showAirportSelectorFirst: false,
  },
};

export default holidayGadget;
